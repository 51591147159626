import { useEffect, useState } from 'react';
import { Button, List, ListItemButton, Typography } from '@mui/material';
import { isEmpty, isNil } from 'ramda';

import TalentsRepository from 'src/repositories/manager/TalentsRepository';
import { TalentSearch } from 'src/types/resources/TalentSearch';
import useModals from 'src/hooks/useModals';
import useLoading from 'src/hooks/useLoading';
import SearchForm from 'src/components/SearchForm';
import Loader from 'src/components/Loader';
import TalentPersonalInfo from 'src/components/TalentPersonalInfo';
import Box from 'src/components/Box';

import styles from './styles';

type SelectResourceProps = {
  onSelectResource: (resource: TalentSearch) => void;
};

const SelectResource: React.FC<SelectResourceProps> = props => {
  const { onSelectResource } = props;
  const { hideModal } = useModals();
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedResource, setSelectedResource] = useState<TalentSearch | null>(null);
  const [groupResources, setGroupResources] = useState<TalentSearch[]>([]);
  const { funcWithLoading: loadGroupResourcesWithLoading, isFinished: isLoadGroupResourcesFinished } = useLoading(
    TalentsRepository.index,
  );

  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, resource: TalentSearch) => {
    setSelectedResource(resource);
  };

  const handleSelectResource = () => {
    onSelectResource(selectedResource);
    hideModal();
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const renderResourceList = () => {
    if (isEmpty(groupResources)) {
      return (
        <Typography sx={styles.noResults} variant="caption">
          No results found...
        </Typography>
      );
    }

    return (
      <>
        <Typography sx={styles.listTitle} variant="h6">
          Select Resource
        </Typography>
        <List sx={styles.resourceList} component="nav" aria-label="main mailbox folders">
          {groupResources.map(resource => (
            <ListItemButton
              key={resource.id}
              sx={styles.resourceListItem}
              selected={resource.id === selectedResource?.id}
              onClick={event => handleListItemClick(event, resource)}
            >
              <TalentPersonalInfo
                avatar={resource.avatarPicture}
                state={resource.state}
                name={resource.fullName}
                city={resource.city}
              />
            </ListItemButton>
          ))}
        </List>
      </>
    );
  };

  useEffect(() => {
    const fetchResources = async () => {
      const response = await loadGroupResourcesWithLoading({ firstName: searchValue });
      setGroupResources(response.results);
    };
    fetchResources();
  }, [searchValue]);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.header}>
        <Typography variant="h3" sx={styles.title}>
          Select Resource
        </Typography>
        <SearchForm
          sx={styles.search}
          placeholder="Search by name"
          onSearch={handleSearch}
          searchEntityType="engagementTalent"
        />
      </Box>
      <Box sx={styles.body}>{!isLoadGroupResourcesFinished ? <Loader /> : renderResourceList()}</Box>
      <Box sx={styles.footer}>
        <Button
          variant="contained"
          sx={styles.button}
          disabled={isNil(selectedResource)}
          onClick={handleSelectResource}
        >
          Add
        </Button>
        <Button variant="outlined" onClick={hideModal} sx={styles.button}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default SelectResource;
