import {
  loadTalents,
  loadMatchedTalentsCount,
  resetTalents,
  TalentSliceStateType,
  TalentsSliceActionsType,
} from 'src/store/organizationAdmin/talentsSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';
import { SearchResourcesFormDataToSubmit } from 'src/forms/SearchResources';

type UseOrganizationAdminTalentsType = Pick<TalentSliceStateType, 'talents' | 'matchedTalentsCount' | 'meta'> &
  TalentsSliceActionsType & {
    isTalentLoadFinished: boolean;
    isTalentLoadIdle: boolean;
    isLoadMatchedTalentsCountPending: boolean;
  };

const useOrganizationAdminTalents = (): UseOrganizationAdminTalentsType => {
  const dispatch = useAppDispatch();
  const {
    meta,
    talents,
    matchedTalentsCount,
    index: { fetchStatus: talentsIndexFetchStatus },
    loadMatchedTalentsCount: { fetchStatus: loadMatchedTalentsCountFetchStatus },
  } = useAppSelector(state => state.organizationAdminTalents);

  const fetchStatus = getFetchStatus(talentsIndexFetchStatus);
  const isTalentLoadFinished = fetchStatus.isFinished;
  const isTalentLoadIdle = fetchStatus.isIdle;
  const isLoadMatchedTalentsCountPending = getFetchStatus(loadMatchedTalentsCountFetchStatus).isPending;
  const defaultParams = { isTalentApprovedByRevuud: true };

  return {
    meta,
    talents,
    matchedTalentsCount,
    isTalentLoadFinished,
    isTalentLoadIdle,
    isLoadMatchedTalentsCountPending,
    loadTalents: (params: Partial<SearchResourcesFormDataToSubmit>) => {
      return dispatch(loadTalents({ ...params, ...defaultParams }));
    },
    loadMatchedTalentsCount: (params?: Partial<SearchResourcesFormDataToSubmit>) => {
      return dispatch(loadMatchedTalentsCount({ ...params, ...defaultParams }));
    },
    resetTalents: () => dispatch(resetTalents()),
  };
};

export default useOrganizationAdminTalents;
