import { useState } from 'react';
import { Alert, Button, TextField, Typography, InputLabel, Checkbox, FormControlLabel } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { isNil } from 'ramda';

import {
  attributesToSubmit,
  CreateOrganizationFormData,
  initialValues,
  getValidationSchema,
  CreateOrganizationFormIncludeFields,
} from 'src/forms/createOrganization';
import UsersPresenter from 'src/presenters/UsersPresenter';
import appRoutes from 'src/routes/appRoutes';
import { Address } from 'src/types/address';
import { Organization } from 'src/types/resources/Organization';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import { ShowParams, UpdateParams } from 'src/repositories/superAdmin/OrganizationsRepository';
import useUsers from 'src/hooks/useUsers';
import useRouter from 'src/hooks/useRouter';
import useOrganizations from 'src/hooks/superAdmin/useOrganizations';
import useModals from 'src/hooks/useModals';
import useSnackbar from 'src/hooks/useSnackbar';
import AddressAutocomplete from 'src/components/AddressAutocomplete';
import Box from 'src/components/Box';
import Select from 'src/components/Select';
import DatePicker from 'src/components/DatePicker';
import { useFeatureFlags } from 'src/hooks';
import { AccountType, AccountTypeLabel } from 'src/enums/AccountType';

import styles from './styles';

type CreateOrganizationFormProps = {
  organization?: Organization;
  showOrganization: (params: ShowParams) => { unwrap: () => void };
  updateOrganization: (params: UpdateParams) => { unwrap: () => void };
};

const SELECT_ITEMS = [
  { value: AccountType.client, label: AccountTypeLabel.client },
  { value: AccountType.prospect, label: AccountTypeLabel.prospect },
  { value: AccountType.test, label: AccountTypeLabel.test },
];

const CreateOrganizationForm: React.FC<CreateOrganizationFormProps> = props => {
  const { featureShowOrgBrrPricingUpdate } = useFeatureFlags();
  const { organization, showOrganization, updateOrganization } = props;
  const [formError, setFormError] = useState<string | null>(null);
  const { currentUser } = useUsers();
  const { createOrganization } = useOrganizations();
  const { hideModal } = useModals();
  const { enqueueSuccessSnackbar } = useSnackbar();
  const { history } = useRouter();

  const isCreateMode = isNil(organization);
  const formTitle = isCreateMode ? 'Create Organization' : 'Edit Organization';
  const submitButtonText = isCreateMode ? 'Create Organization' : 'Save';
  const snackbarText = isCreateMode ? 'Organization was created' : 'Organization was updated';

  const licenseDateIsHidden = featureShowOrgBrrPricingUpdate && !isCreateMode;

  const accountTypeSelectorDisabled = featureShowOrgBrrPricingUpdate
    ? !isCreateMode && organization?.accountType !== AccountType.prospect
    : false;

  const hasSuperAdminAccess = UsersPresenter.hasSuperAdminAccess(currentUser);

  const getAccountTypeValuesForEdit = () => {
    if (!featureShowOrgBrrPricingUpdate) {
      return SELECT_ITEMS;
    }

    if (organization.accountType === AccountType.prospect) {
      return SELECT_ITEMS.filter(item => item.value !== AccountType.test);
    }

    return SELECT_ITEMS;
  };

  const handleSubmit = async (
    formData: CreateOrganizationFormData,
    { setErrors }: FormikHelpers<CreateOrganizationFormData>,
  ) => {
    const attributesToInclude: CreateOrganizationFormIncludeFields = [];

    if (
      formData.accountType === AccountType.client ||
      (formData.accountType === AccountType.test && featureShowOrgBrrPricingUpdate)
    ) {
      attributesToInclude.push('licenseDate');
    }

    if (formData.accountType === AccountType.client) {
      attributesToInclude.push('purchaseOrders');
    }

    const params = attributesToSubmit(formData, attributesToInclude);
    try {
      if (isCreateMode) {
        const { data: createdOrganization } = await createOrganization(params).unwrap();
        hideModal();
        history.push(appRoutes.superAdmin.organizationPath(createdOrganization.id), { activeTabIndex: 1 });
      } else {
        await updateOrganization({ id: organization.id, params }).unwrap();
        showOrganization({ id: organization.id });
      }
      enqueueSuccessSnackbar(snackbarText);
      hideModal();
    } catch (error) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setFormError(error.response.data.errors.detail);
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleBlur,
    setValues,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    handleSubmit: handleFormikSubmit,
  } = useFormik<CreateOrganizationFormData>({
    initialValues: initialValues(organization, featureShowOrgBrrPricingUpdate),
    validationSchema: getValidationSchema(organization, featureShowOrgBrrPricingUpdate),
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  const handleAddressChange = (address: Address) => {
    setValues({
      ...values,
      address1: address.streetLine,
      address2: address.secondary,
      city: address.city,
      state: address.state,
      zipCode: address.zipcode,
    });
  };

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <Box sx={styles.modalWrapper}>
        <Typography variant="h3">{formTitle}</Typography>
        <form noValidate onSubmit={handleFormikSubmit}>
          {hasSuperAdminAccess && (
            <Box sx={styles.row}>
              <Select
                id="accountType"
                name="accountType"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.accountType}
                values={isCreateMode ? SELECT_ITEMS : getAccountTypeValuesForEdit()}
                error={touched.accountType && Boolean(errors.accountType)}
                helperText={touched.accountType && errors.accountType}
                disabled={accountTypeSelectorDisabled}
                label="Account Type"
              />
            </Box>
          )}

          <Box sx={styles.row}>
            <TextField
              id="name"
              label="Organization Name"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
          </Box>

          {!licenseDateIsHidden &&
            hasSuperAdminAccess &&
            (values.accountType === AccountType.client ||
              (values.accountType === AccountType.test && featureShowOrgBrrPricingUpdate)) && (
              <>
                <InputLabel>License date</InputLabel>
                <Box sx={[styles.row, { mt: 0 }]}>
                  <Box sx={styles.dateRow}>
                    <DatePicker
                      value={values.licenseStartDate || null}
                      onChange={date => {
                        setFieldValue('licenseStartDate', date);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          sx={styles.dateField}
                          id="licenseStartDate"
                          name="licenseStartDate"
                          inputProps={{ ...params.inputProps, placeholder: 'Start Date' }}
                          error={touched.licenseStartDate && Boolean(errors.licenseStartDate)}
                          helperText={touched.licenseStartDate && errors.licenseStartDate}
                          onBlur={handleBlur}
                        />
                      )}
                    />
                    <DatePicker
                      minDate={new Date(values.licenseStartDate)}
                      value={values.licenseEndDate || null}
                      onChange={date => {
                        setFieldValue('licenseEndDate', date);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          sx={styles.dateField}
                          id="licenseEndDate"
                          name="licenseEndDate"
                          inputProps={{ ...params.inputProps, placeholder: 'End Date' }}
                          error={touched.licenseEndDate && Boolean(errors.licenseEndDate)}
                          helperText={touched.licenseEndDate && errors.licenseEndDate}
                          onBlur={handleBlur}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </>
            )}
          {values.accountType === AccountType.client && (
            <Box>
              <Typography fontWeight="600" mb="4px">
                Purchase Orders
              </Typography>
              <FormControlLabel
                label="Requires Purchase Orders on Engagements"
                control={
                  <Checkbox
                    color="primary"
                    checked={values.isEngagementRequiresPurchaseOrder}
                    onChange={date => {
                      setFieldValue('isEngagementRequiresPurchaseOrder', date.target.checked);
                    }}
                  />
                }
              />
            </Box>
          )}

          <Box sx={styles.row}>
            <TextField
              id="website"
              label="Website"
              type="url"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.website}
              error={touched.website && Boolean(errors.website)}
              helperText={touched.website && errors.website}
            />
          </Box>
          <AddressAutocomplete
            initialValue={values.address1}
            onChangeAddress={handleAddressChange}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            setFieldTouched={setFieldTouched}
            error={touched.address1 && errors.address1}
          />
          <Box sx={styles.row}>
            <TextField
              id="address2"
              label="Address Line 2"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address2}
              error={touched.address2 && Boolean(errors.address2)}
              helperText={touched.address2 && errors.address2}
            />
          </Box>
          <Box sx={styles.row}>
            <TextField
              id="city"
              label="City"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.city}
              error={touched.city && Boolean(errors.city)}
              helperText={touched.city && errors.city}
            />
          </Box>
          <Box sx={styles.row}>
            <TextField
              id="state"
              label="State"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.state}
              error={touched.state && Boolean(errors.state)}
              helperText={touched.state && errors.state}
            />
            <TextField
              id="zipCode"
              label="Zip"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.zipCode}
              error={touched.zipCode && Boolean(errors.zipCode)}
              helperText={touched.zipCode && errors.zipCode}
              sx={styles.zipCode}
            />
          </Box>
          {hasSuperAdminAccess && (
            <Box sx={styles.row}>
              <TextField
                id="note"
                label="Note"
                multiline
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.note}
                error={touched.note && Boolean(errors.note)}
                helperText={touched.note && errors.note}
              />
            </Box>
          )}
          <Box sx={styles.buttons}>
            <Button type="submit" variant="contained" sx={styles.button} disabled={isSubmitting}>
              {submitButtonText}
            </Button>
            <Button variant="outlined" onClick={hideModal} sx={styles.button}>
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default CreateOrganizationForm;
