import { useEffect, useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { Button, Typography, Alert } from '@mui/material';
import { isEmpty } from 'ramda';

import GroupMembersRepository from 'src/repositories/manager/GroupMembersRepository';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import { createGroupsForSelect, createSubgroupsForSelect } from 'src/utils/group';
import useModals from 'src/hooks/useModals';
import useSnackbar from 'src/hooks/useSnackbar';
import useGroups from 'src/hooks/useGroups';
import useSubgroups from 'src/hooks/useSubgroups';
import Select from 'src/components/Select';
import Box from 'src/components/Box';

import styles from './styles';

type InviteToGroupFormProps = {
  talentId: number;
};

type FormData = {
  groupId: number;
  subgroupId: number;
};

const InviteToGroupForm: React.FC<InviteToGroupFormProps> = props => {
  const { talentId } = props;
  const [formError, setFormError] = useState<string | null>(null);
  const { hideModal } = useModals();
  const { enqueueSuccessSnackbar } = useSnackbar();
  const { groups, loadGroups } = useGroups();
  const { subgroups, loadSubgroups } = useSubgroups();

  const handleSubmit = async (formData: FormData, { setErrors }: FormikHelpers<FormData>) => {
    const params = {
      talentId,
      subgroupId: formData.subgroupId,
    };
    try {
      await GroupMembersRepository.create(params);
      hideModal();
      enqueueSuccessSnackbar('The Talent was added to the Group');
    } catch (error) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setFormError(error.response.data.errors.nonFieldErrors);
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const { values, handleChange, submitForm } = useFormik<FormData>({
    initialValues: {
      groupId: 0,
      subgroupId: 0,
    },
    onSubmit: handleSubmit,
  });

  const groupItems = createGroupsForSelect(groups);
  const subgroupItems = createSubgroupsForSelect(subgroups[values?.groupId]?.subgroups);

  useEffect(() => {
    loadGroups();
  }, []);

  useEffect(() => {
    if (values.groupId) {
      loadSubgroups({ groupId: values.groupId });
    }
  }, [values.groupId]);

  return (
    <>
      <Box sx={styles.wrapper}>
        {formError && <Alert severity="error">{formError}</Alert>}
        <Typography variant="h3" sx={styles.title}>
          Add to a Group
        </Typography>
        <Box sx={styles.row}>
          <Select
            name="groupId"
            values={groupItems}
            value={values.groupId}
            onChange={handleChange}
            placeholder="Select Group"
          />
        </Box>
        <Box sx={styles.row}>
          <Select
            name="subgroupId"
            values={subgroupItems}
            value={values.subgroupId}
            onChange={handleChange}
            placeholder="Select Subgroup"
            disabled={isEmpty(subgroupItems)}
          />
        </Box>
        <Box sx={styles.buttons}>
          <Button variant="contained" onClick={submitForm} sx={styles.button} disabled={!values.subgroupId}>
            Add
          </Button>
          <Button variant="outlined" onClick={hideModal} sx={styles.button}>
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default InviteToGroupForm;
