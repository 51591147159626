import { isNil, omit } from 'ramda';
import * as yup from 'yup';
import { parseISO } from 'date-fns';

import { ValidationError } from 'src/enums/Validation';
import { AccountType } from 'src/enums/AccountType';
import { Organization } from 'src/types/resources/Organization';
import { DocumentFile } from 'src/forms/talents/documents';
import { replaceNullKeys } from 'src/utils/keysConverter';
import { getMaxFieldLengthErrorMessage } from 'src/utils/validation';
import { convertDateToServerFormat } from 'src/utils/date';

export type CreateOrganizationFormData = {
  accountType: AccountType;
  name: string;
  website: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  note: string;
  logo?: DocumentFile | null;
  licenseStartDate?: Date | null;
  licenseEndDate?: Date | null;
  isEngagementRequiresPurchaseOrder?: boolean;
};

export type CreateOrganizationFormDataToSubmit = Omit<
  CreateOrganizationFormData,
  'logo' | 'licenseStartDate' | 'licenseEndDate'
> & {
  logo?: File | null;
  licenseStartDate?: string;
  licenseEndDate?: string;
};

export type CreateOrganizationFormIncludeFields = ('licenseDate' | 'purchaseOrders')[];

const defaulDateValidation = yup
  .date()
  .min(new Date(2021, 0, 1), 'License year should be later than 2020')
  .nullable()
  .typeError(ValidationError.date)
  .default(null);

export const getValidationFields = (organization: Organization, feature: boolean) => ({
  accountType: yup
    .mixed()
    .oneOf(Object.values(AccountType))
    .required(ValidationError.default)
    .default(AccountType.prospect)
    .label('Account Type'),
  name: yup.string().required(ValidationError.default).default('').label('Name'),
  licenseStartDate: yup.lazy((value, schemaContext) => {
    if (organization) {
      return defaulDateValidation;
    }

    if (!feature && schemaContext.parent.accountType === AccountType.client) {
      return defaulDateValidation;
    }

    if (
      feature &&
      (schemaContext.parent.accountType === AccountType.test || schemaContext.parent.accountType === AccountType.client)
    ) {
      return defaulDateValidation.required(ValidationError.default);
    }

    return defaulDateValidation;
  }),
  licenseEndDate: yup.lazy((value, schemaContext) => {
    if (organization) {
      return defaulDateValidation.min(yup.ref('licenseStartDate'), ValidationError.dateEndBeforeDateStart);
    }

    if (!feature && schemaContext.parent.accountType === AccountType.client) {
      return defaulDateValidation.min(yup.ref('licenseStartDate'), ValidationError.dateEndBeforeDateStart);
    }

    if (
      feature &&
      (schemaContext.parent.accountType === AccountType.test || schemaContext.parent.accountType === AccountType.client)
    ) {
      return defaulDateValidation
        .min(yup.ref('licenseStartDate'), ValidationError.dateEndBeforeDateStart)
        .required(ValidationError.default);
    }
    return defaulDateValidation.min(yup.ref('licenseStartDate'), ValidationError.dateEndBeforeDateStart);
  }),
  website: yup.string().url(ValidationError.url).default('').label('Website'),
  address1: yup
    .string()
    .nullable()
    .required(ValidationError.default)
    .max(300, getMaxFieldLengthErrorMessage(300))
    .default('')
    .label('Address Line 1'),
  address2: yup.string().max(300, getMaxFieldLengthErrorMessage(300)).default('').label('Address Line 2'),
  city: yup
    .string()
    .required(ValidationError.default)
    .max(150, getMaxFieldLengthErrorMessage(150))
    .default('')
    .label('City'),
  state: yup
    .string()
    .required(ValidationError.default)
    .max(150, getMaxFieldLengthErrorMessage(150))
    .default('')
    .label('State'),
  zipCode: yup
    .string()
    .required(ValidationError.default)
    .max(10, getMaxFieldLengthErrorMessage(10))
    .default('')
    .label('ZIP'),
  note: yup.string().default('').max(500, getMaxFieldLengthErrorMessage(500)).label('Note'),
  isEngagementRequiresPurchaseOrder: yup.boolean(),
});

export const getValidationSchema = (organization: Organization, feature: boolean) =>
  yup.object(getValidationFields(organization, feature));

export const initialValues = (
  organization?: Organization,
  featurePricingUpdate?: boolean,
): CreateOrganizationFormData => {
  const validationSchema = getValidationSchema(organization, featurePricingUpdate);

  if (isNil(organization)) {
    return validationSchema.getDefault();
  }

  const defaultValues: CreateOrganizationFormData = {
    ...omit(['logo', 'licenseStartDate', 'licenseEndDate'], replaceNullKeys<Organization>(organization, '')),
    licenseEndDate: organization.licenseEndDate ? parseISO(organization.licenseEndDate) : null,
    licenseStartDate: organization.licenseStartDate ? parseISO(organization.licenseStartDate) : null,
  };

  return defaultValues;
};

export const attributesToSubmit = (
  values: CreateOrganizationFormData,
  includeFields: CreateOrganizationFormIncludeFields,
): CreateOrganizationFormDataToSubmit => {
  const result: CreateOrganizationFormDataToSubmit = {
    ...omit(['logo', 'licenseStartDate', 'licenseEndDate', 'purchaseOrders'], values),
  };
  if (includeFields.includes('licenseDate')) {
    result.licenseEndDate = values.licenseEndDate ? convertDateToServerFormat(values.licenseEndDate) : null;
    result.licenseStartDate = values.licenseStartDate ? convertDateToServerFormat(values.licenseStartDate) : null;
  }

  if (includeFields.includes('purchaseOrders')) {
    result.isEngagementRequiresPurchaseOrder = values.isEngagementRequiresPurchaseOrder;
  }
  return result;
};
