import { SxStyles } from 'src/types/theme';
import { COLORS } from 'src/theme/palette';
import checkCircleIcon from 'src/assets/images/checkCircleIcon.svg';

const styles: SxStyles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '512px',
    height: '448px',
    overflow: 'hidden',
  },
  header: {
    padding: '24px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
  },
  footer: {
    borderTop: `1px solid ${COLORS.black10}`,
    padding: '24px',
  },
  resourceList: {
    overflow: 'hidden auto',
  },
  resourceListItem: {
    position: 'relative',
    paddingLeft: '24px',
    paddingRight: '24px',
    '&::after': {
      display: 'none',
      content: '""',
      position: 'absolute',
      top: '50%',
      right: '24px',
      transform: 'translateY(-50%)',
      width: '24px',
      height: '24px',
      background: `url("${checkCircleIcon}") no-repeat center`,
    },
    '&.Mui-selected': {
      backgroundColor: COLORS.orange11,
    },
    '&.Mui-selected::after': {
      display: 'block',
    },
  },
  title: {
    paddingBottom: '12px',
  },
  listTitle: {
    color: COLORS.black40,
    marginBottom: '5px',
    padding: '0 24px',
  },
  noResults: {
    padding: '0 24px',
    color: COLORS.black40,
  },
  button: {
    padding: '7px 47px',
    marginRight: '10px',
  },
  buttons: {
    marginTop: '40px',
  },
};

export default styles;
